import React from "react";

const ContrastIcon = props => (
  <svg width={24} height={24} fill="none" {...props}>
    <path
      d="M11.5 18a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0-1a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9zM12 5.5a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 1 0v3zm0 17a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 1 0v3zm4.803-14.596a.5.5 0 1 1-.707-.707l2.05-2.05a.5.5 0 0 1 .708.707l-2.05 2.05zm-11.95 11.95a.5.5 0 0 1-.707-.708l2.05-2.05a.5.5 0 1 1 .708.707l-2.05 2.05zM18.5 13a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm-17 0a.5.5 0 0 1 0-1h3a.5.5 0 0 1 0 1h-3zm14.596 4.803a.5.5 0 1 1 .707-.707l2.05 2.05a.5.5 0 0 1-.707.708l-2.05-2.05zM4.146 5.853a.5.5 0 1 1 .708-.707l2.05 2.05a.5.5 0 0 1-.707.708l-2.05-2.05z"
      fill="#fff"
    />
  </svg>
);

export default ContrastIcon;
